import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@bb-ui/react-library';
import { useCognitoAuthContext } from 'contexts/CognitoAuthContext';
import { Redirect } from 'pages/Redirect';

export const SignOut: React.FC = () => {
  const cognito = useCognitoAuthContext();
  const { t } = useTranslation();

  if (cognito.status === 'loading') {
    return <CircularProgress ariaLabel={t('global.loadingAriaLabel')!} />;
  }

  if (cognito.status === 'authenticated') {
    cognito.signOut();

    return <CircularProgress ariaLabel={t('global.loadingAriaLabel')!} />;
  }

  return <Redirect />;
};

export default SignOut;
