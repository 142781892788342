import * as React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { HelpCircle } from '@bb-ui/icons/dist/small/HelpCircle';
import { Link } from '@bb-ui/react-library/dist/components/Link';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useTranslation } from 'react-i18next';
import { Logo } from 'components/Logo';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    bottom: 0,
    borderTop: `1px solid ${theme.palette.secondary.main}`,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(4),

    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
      padding: '0 60px',
    },
  },
  helpLink: {
    color: theme.palette.primary.main,
    display: 'inline-flex',
    alignItems: 'center',
    marginLeft: 'auto',
    fontWeight: theme.typography.fontWeightSemiBold,
    lineHeight: 1.6,
    textDecoration: 'none',
  },
  helpLinkIcon: {
    marginRight: theme.spacing(1),
  },
  logo: {
    height: 'auto',
    marginRight: '24px',
    maxHeight: '100%',
    width: '125px',
  },
  start: {
    display: 'grid',
    flexGrow: 1,
    gridGap: theme.spacing(2),

    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  startItem: {
    color: theme.palette.text.secondary,

    [theme.breakpoints.up('sm')]: {
      marginRight: '20px',
    },
  },
  startLinks: {
    [theme.breakpoints.down('md')]: {
      display: 'grid',
      gridAutoColumns: 'min-content',
      gridGap: theme.spacing(2),
      gridTemplateAreas: '"link link"',
    },
  },
}));

export const Footer: React.FC = (props) => {
  const { t } = useTranslation();
  const styles = useStyles(props);

  return (
    <footer className={styles.root}>
      <div className={styles.start}>
        <div>
          {' '}
          {/* Needed to keep logo from overflowing footer on Safari, even as recent as 13 */}
          <Logo className={styles.logo} />
        </div>
        <Typography className={styles.startItem}>{t('footer.copyright')}</Typography>
        <div className={styles.startLinks}>
          <Link className={styles.startItem} href={t('links:privacyPolicy')}>
            {t('footer.privacy')}
          </Link>
          <Link className={styles.startItem} href={t('links:terms')}>
            {t('footer.terms')}
          </Link>
        </div>
      </div>
      <div>
        <Link href={t('links:help')} className={styles.helpLink}>
          <HelpCircle className={styles.helpLinkIcon} />
          {t('footer.help')}
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
