import * as React from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@bb-ui/react-library';
import { CognitoAuthContextProvider } from 'contexts/CognitoAuthContext';
import { useSignInMethodContext } from 'contexts/SignInMethodContext/SignInMethodContext';
import { useIdpDetail } from 'hooks/useIdpDetail';
import { ErrorMessage } from 'components/ErrorMessage';

export const AuthProvider = () => {
  const { t } = useTranslation();
  const { idpId, tenantId } = useSignInMethodContext();
  const { idp, loading: idpLoading } = useIdpDetail(tenantId, idpId);

  if (idpLoading) {
    return <CircularProgress ariaLabel={t('global.loadingAriaLabel')!} />;
  }

  if (!idp) {
    return (
      <ErrorMessage data-testid="error-loading-idp-data" title={t('global.error.idpsFetchFail')} />
    );
  }

  const { brokerData } = idp;
  const { userPoolId, clientId, defaultHostname: hostname } = brokerData;

  return (
    <CognitoAuthContextProvider
      clientId={clientId!}
      domain={idp.brokerData.domain}
      hostname={hostname}
      identityProviderId={idp.id}
      identityProviderName={idp.brokerData.identityProviderName}
      identityProviderType={idp.type}
      tenantId={tenantId!}
      userPoolId={userPoolId!}
    >
      <Outlet />
    </CognitoAuthContextProvider>
  );
};

export default AuthProvider;
