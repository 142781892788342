import * as React from 'react';
import { CircularProgress } from '@bb-ui/react-library';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCognitoAuthContext } from 'contexts/CognitoAuthContext';
import { UserPass } from 'components/UserPass';
import { IdentityProvider } from 'hooks/useTenantIdps';

export interface SignInUserPassProps {
  idp: IdentityProvider;
  onBack?: () => void;
}

export const SignInUserPass: React.FC<SignInUserPassProps> = (props) => {
  const { idp, onBack } = props;
  const [authError, setAuthError] = React.useState<Error>();
  const [password, setPassword] = React.useState('');
  const [signingIn, setSigningIn] = React.useState(false);
  const [username, setUsername] = React.useState('');
  const cognito = useCognitoAuthContext();
  const { t } = useTranslation();
  const { search } = useLocation();
  const navigate = useNavigate();

  async function handleSignIn() {
    setSigningIn(true);

    try {
      if (cognito.status !== 'unauthenticated') {
        throw new Error('not-authenticated');
      }
      await cognito.signIn(username, password);
    } catch (error) {
      setAuthError(error as Error);
      setPassword('');
    }

    setSigningIn(false);
  }

  React.useEffect(() => {
    if (cognito.status === 'authenticated') {
      navigate(`/redirect`);
    }
  }, [cognito.status, navigate, search]);

  if (cognito.status === 'loading' || cognito.status === 'authenticated') {
    return <CircularProgress ariaLabel={t('global.loadingAriaLabel')!} />;
  }

  return (
    <UserPass
      errorMessage={authError?.message}
      header={idp.displayName ?? ''}
      onChangePassword={setPassword}
      onChangeUsername={setUsername}
      onSignIn={handleSignIn}
      password={password}
      signingIn={signingIn}
      username={username}
      onBack={onBack}
    />
  );
};

export default SignInUserPass;
