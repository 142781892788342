import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  createStyles,
  makeStyles,
  Theme,
  IconButton,
  CircularProgress,
  PrimaryButton,
  TextField,
  Typography,
} from '@bb-ui/react-library';
import { ChevronLeft } from '@bb-ui/icons/dist/small/ChevronLeft';

const useStyles = makeStyles((theme: Theme) => createStyles({
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: '12px',
  },
  header: {
    color: theme.palette.text.secondary,
    textAlign: 'center',
  },
  root: {
    display: 'grid',
    gridGap: theme.spacing(3),
    margin: '0 auto',

    [theme.breakpoints.up('sm')]: {
      width: '360px',
    },
  },
  signInFields: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
  backButton: {
    borderTop: `1px solid ${theme.palette.secondary.main}`,
    paddingTop: theme.spacing(4),
    textAlign: 'left',
  },
}));

export interface UserPassProps {
  errorMessage?: string;
  header: string;
  onChangePassword: (value: string) => void;
  onChangeUsername: (value: string) => void;
  onSignIn: () => void;
  password: string;
  signingIn?: boolean;
  username: string;
  onBack?: () => void;
}

/**
 * Username/password fields with submit button and error message.
 */
export const UserPass: React.FC<UserPassProps> = (props) => {
  const { errorMessage, header, onChangePassword, onChangeUsername, onSignIn, password, signingIn, username, onBack } =
    props;
  const classes = useStyles();
  const { t } = useTranslation();

  const commonFieldProps = {
    floatingLabel: true,
    fullWidth: true,
  };
  const canSubmit = username.trim() !== '' && password.trim() !== '';
  const handleSubmit: React.FormEventHandler = (event) => {
    event.preventDefault();
    onSignIn();
  };

  return (
    <form className={classes.root} onSubmit={handleSubmit} data-testid="user-pass-sign-in">
      <Typography variant="h2" className={classes.header}>
        {header}
      </Typography>
      <div className={classes.signInFields}>
        <TextField
          id="user-pass-sign-in-username"
          label={t('userPassSignIn.username')}
          onChange={(e) => onChangeUsername(e.target.value)}
          value={username}
          {...commonFieldProps}
        />
        <TextField
          id="user-pass-sign-in-password"
          label={t('userPassSignIn.password')}
          onChange={(e) => onChangePassword(e.target.value)}
          type="password"
          value={password}
          {...commonFieldProps}
        />
      </div>
      {errorMessage && <Typography className={classes.errorMessage}>{errorMessage}</Typography>}
      <PrimaryButton disabled={!canSubmit || signingIn} fullWidth type="submit" data-testid="user-pass-sign-in-submit">
        {signingIn ? (
          <CircularProgress ariaLabel={t('userPassSignIn.signingInAriaLabel')!} size="small" />
        ) : (
          t('userPassSignIn.signIn')
        )}
      </PrimaryButton>
      {onBack && (
        <div className={classes.backButton}>
          <IconButton data-testid="back-button" onClick={onBack}>
            <ChevronLeft />
            <Typography component="span" variant="inherit">
              {t('userPassSignIn.back')}
            </Typography>
          </IconButton>
        </div>
      )}
    </form>
  );
};

export default UserPass;
