import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@bb-ui/react-library';

const dotRadius = 3;
const radius = 8;

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    backgroundColor: theme.palette.focus.main,
    alignItems: 'center',
    border: `2px solid ${theme.palette.focus.main}`,
    borderRadius: radius,
    display: 'inline-flex',
    height: radius * 2,
    justifyContent: 'center',
    width: radius * 2,

    '&::before': {
      backgroundColor: theme.palette.background.default,
      borderRadius: dotRadius,
      border: `${dotRadius}px solid transparent`,
      content: '""',
      display: 'block',
      height: dotRadius * 2,
      width: dotRadius * 2,
    },
  },
}));

/**
 * Same appearance as the standard one, but blue instead of green. This is a
 * copy/paste unfortunately because it doesn't seem like we can override just
 * that on the bb-ui one.
 */
export const CustomRadioSelectedIcon: React.FC = (props) => {
  const styles = useStyles(props);

  return <span className={styles.root} aria-hidden="true" />;
};
