import * as React from 'react';
import { Amplify, Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { v4 as uuidv4 } from 'uuid';

/**
 * We are initializing Cognito. The user's authentication status is unknown.
 */
export interface UseCognitoAuthLoading {
  status: 'loading';
}

/**
 * Cognito has initialized, but the user is not authenticated. We use Amplify to
 * remember this, so any previously-loaded tokens are in local storage (e.g.
 * there is no global session in the user's browser).
 */
export interface UseCognitoAuthUnauthed {
  status: 'unauthenticated';
  /**
   * Error returned from the most recent attempt to authenticate.
   */
  authError?: Error;
  /**
   * Tries to sign the user in using federated signin.
   */
  federatedSignIn: () => Promise<void>;
  /**
   * Tries to sign the user in with the username and password provided. If
   * authentication fails, the promise returned rejects but `status` does not
   * change.
   */
  signIn: (username: string, password: string) => Promise<void>;
}

/**
 * Cognito has initialized and the user is authenticated, either by retrieving
 * previous tokens from local storage or by signing in.
 */
export interface UseCognitoAuthAuthed {
  status: 'authenticated';
  /**
   * Signs the user out. When this completes, context `state` changes to
   * `unauthenticated`. If this fails, the promise returned rejects but `state`
   * does not change.
   */
  signOut: () => Promise<void>;
  /**
   * Cognito-minted JWTs for the user.
   */
  tokens: {
    access: string;
    id: string;
    refresh: string;
  };
  /**
   * Used to indicate the lifetime in seconds of the Access Token.
   * Value is calculation of token `iat` (issued at) subtracted from `exp` (expirtation time).
   */
  expiresIn: number;
  /**
   * The current user object.
   */
  user: CognitoUser;
}

export type UseCognitoAuthParams =
  | UseCognitoAuthLoading
  | UseCognitoAuthUnauthed
  | UseCognitoAuthAuthed;

export const CognitoAuthContext = React.createContext<UseCognitoAuthParams | undefined>(undefined);

export function useCognitoAuthContext() {
  const cognitoContext = React.useContext(CognitoAuthContext);

  if (!cognitoContext) {
    throw new Error('useCognitoAuthContext must be used within a CognitoAuthContext.Provider');
  }

  return cognitoContext;
}

export interface CognitoAuthContextProviderProps {
  /**
   * Domain to connect to (used only for federated sign in).
   */
  domain?: string;
  /**
   * Tenant ID to use during sign in (for Learn consumers).
   */
  tenantId: string;
  /**
   * ID of the Identity Provider to use (for Learn consumers).
   */
  identityProviderId: string;
  /**
   * Name of the Identity Provider to use (for federated sign in). This should
   * match the name of the IDP in the AWS console, not a display name (or the
   * Cognito user pool name).
   */
  identityProviderName?: string;
  /**
   * Type of the Identity Provider to use.
   */
  identityProviderType?: string;
  /**
   * Client ID to use when connecting to the Cognito pool.
   */
  clientId: string;
  /**
   * Hostname to send during sign in (for Learn consumers).
   */
  hostname?: string;
  /**
   * ID of the Cognito pool to use.
   */
  userPoolId: string;
}

// We specify this type because Amplify.configure()'s type definition takes
// `any`.

interface AmplifyAuthConfig {
  aws_user_pools_id: string;
  aws_user_pools_web_client_id: string;
  /**
   * Used for federated sign in. Because we aren't using Cognito hosted UI, we
   * have to specify this manually. Most of these arguments will map to an
   * /authorize OAuth call Amplify makes for us.
   *
   * @see https://docs.amplify.aws/lib/client-configuration/configuring-amplify-categories/q/platform/js/#scoped-configuration
   */
  oauth: {
    clientID: string;
    domain: string;
    responseType: string;
    redirectSignIn: string;
    redirectSignOut: string;
  };
}

/**
 * Provides access to Cognito's authentication API.
 * @see https://docs.amplify.aws/lib/auth/getting-started/q/platform/js/
 * @see https://aws.amazon.com/blogs/mobile/accessing-your-user-pools-using-the-amazon-cognito-identity-sdk-for-javascript/
 */
export const CognitoAuthContextProvider: React.FC<CognitoAuthContextProviderProps> = (props) => {
  const {
    children,
    domain,
    tenantId,
    identityProviderId,
    identityProviderName,
    identityProviderType,
    clientId,
    hostname,
    userPoolId,
  } = props;
  const [amplifyConfig, setAmplifyConfig] = React.useState<AmplifyAuthConfig>();
  const [status, setStatus] = React.useState<UseCognitoAuthParams['status']>('loading');
  const [authError, setAuthError] = React.useState<Error>();
  const [tokens, setTokens] = React.useState<UseCognitoAuthAuthed['tokens']>();
  const [user, setUser] = React.useState<CognitoUser>();
  const [expiresIn, setExpiresIn] = React.useState<number>();

  // Configure Amplify when the user pool, client ID, region, or tenant ID
  // changes.

  React.useEffect(() => {
    if (
      amplifyConfig?.aws_user_pools_id !== userPoolId ||
      amplifyConfig?.aws_user_pools_web_client_id !== clientId ||
      amplifyConfig.oauth.domain !== (domain ?? '')
    ) {
      const config: AmplifyAuthConfig = {
        aws_user_pools_id: userPoolId,
        aws_user_pools_web_client_id: clientId,
        oauth: {
          clientID: clientId,
          domain: domain ?? '',
          redirectSignIn: `${window.location.origin}${process.env.PUBLIC_URL}/redirect`,
          redirectSignOut: `${window.location.origin}${process.env.PUBLIC_URL}/redirect`,
          responseType: 'code',
        },
      };

      Amplify.configure(config);
      setAmplifyConfig(config);

      if (status !== 'loading') {
        setStatus('loading');
        setTokens(undefined);
        setUser(undefined);
      }
    }
  }, [amplifyConfig, clientId, domain, status, tenantId, userPoolId]);

  const getSession = React.useCallback(async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const session = await Auth.currentSession();

      if (session && user) {
        const accessToken = session.getAccessToken();

        setAuthError(undefined);
        setTokens({
          access: accessToken.getJwtToken(),
          id: session.getIdToken().getJwtToken(),
          refresh: session.getRefreshToken().getToken(),
        });
        setExpiresIn(accessToken.getExpiration() - accessToken.getIssuedAt());
        setUser(user);
        setStatus('authenticated');
      } else {
        setStatus('unauthenticated');
        setTokens(undefined);
        setUser(undefined);
      }
    } catch (error) {
      setAuthError(error as Error);
      setStatus('unauthenticated');
      setTokens(undefined);
      setUser(undefined);
    }
  }, []);

  const federatedSignIn = React.useCallback(async () => {
    if (!identityProviderName) {
      setAuthError(new Error('Identity provider has no name configured'));
    } else if (!domain) {
      setAuthError(new Error('No domain configured'));
    } else {
      setAuthError(undefined);
      await Auth.federatedSignIn({ customProvider: identityProviderName });
      getSession();
    }
  }, [domain, getSession, identityProviderName]);

  const signIn = React.useCallback(
    async (username: string, password: string) => {
      setAuthError(undefined);

      const challengeResponse = JSON.stringify({
        tenantId,
        hostname,
        password,
      });
      try {
        if (identityProviderType === 'Testing') {
          await Auth.signIn(username, password);
        } else {
          const user = await Auth.signIn(username);
          await Auth.sendCustomChallengeAnswer(user, challengeResponse);
        }
      } catch (error) {
        const signInError = error as Error;

        // Because the entered username may not be the learnUsername, we need the real password to get the learnUsername back
        // from Learn. The real password is not stored in the user pool, but we do provide a uuid as the password that is
        // stored in the user pool to make Cognito happy, but it can never be used since we bypass the default auth flow.
        if (signInError.name === 'UserNotFoundException') {
          const signupParams = {
            username,
            password: uuidv4(),
            attributes: {
              'custom:identityProviderId': identityProviderId,
              'custom:tenantId': tenantId,
            },
            clientMetadata: {
              tenantId,
              hostname: hostname ?? '',
              password,
            },
          };
          await Auth.signUp(signupParams);
          const user = await Auth.signIn(username);
          await Auth.sendCustomChallengeAnswer(user, challengeResponse);
        } else {
          throw error;
        }
      }
      getSession();
    },
    [getSession, hostname, tenantId, identityProviderId, identityProviderType]
  );

  const signOut = React.useCallback(async () => {
    await Auth.signOut();
    setStatus('unauthenticated');
    setTokens(undefined);
    setUser(undefined);
  }, []);

  // Try initializing Cognito on initial render.

  React.useEffect(() => {
    if (status === 'loading') {
      getSession();
    }
  }, [getSession, status]);

  const context: UseCognitoAuthParams = React.useMemo(() => {
    switch (status) {
      case 'loading':
        return { status };
      case 'authenticated':
        return {
          signOut,
          status,
          tokens: tokens!,
          user: user!,
          expiresIn: expiresIn!,
        };
      case 'unauthenticated':
        return { authError, federatedSignIn, signIn, status };
    }
  }, [status, signOut, tokens, user, expiresIn, authError, federatedSignIn, signIn]);

  return <CognitoAuthContext.Provider value={context}>{children}</CognitoAuthContext.Provider>;
};

export default CognitoAuthContextProvider;
