import { CircularProgress } from '@bb-ui/react-library';
import { useTranslation } from 'react-i18next';
import { useSignInMethodContext } from 'contexts/SignInMethodContext/SignInMethodContext';
import { useIdpDetail } from 'hooks/useIdpDetail';
import * as React from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { SignInFederated } from './SignInFederated';
import { SignInUserPass } from './SignInUserPass';

export const SignIn: React.FC = () => {
  const { idpId: routeIdpId } = useParams();
  const navigate = useNavigate();
  const { idpId, setIdpId, tenantId } = useSignInMethodContext();
  const { idp } = useIdpDetail(tenantId, idpId);
  const { t } = useTranslation();

  // If we have a different IDP ID in storage than what was in the route, update
  // it.

  React.useEffect(() => {
    if (routeIdpId !== idpId && setIdpId) {
      setIdpId(routeIdpId);
    }
  }, [idpId, routeIdpId, setIdpId]);

  // If we have no IDP ID, send the user back to choose one. We don't need to
  // set the querystring because tenant ID should already be in local storage
  // thanks to SignInMethodProvider.

  if (!idpId || !setIdpId) {
    return <Navigate to="/" />;
  }

  // If we don't have IDP data yet, show a spinner.

  if (!idp) {
    return <CircularProgress id="loading-idp" label={t('global.loadingAriaLabel')} />;
  }

  function handleBack() {
    setIdpId!(undefined);
    navigate('/');
  }

  // Default to user/pass auth, same as in Auth0 sign in.

  if (idp.type === 'SAML') {
    return <SignInFederated />;
  }

  return (
    <SignInUserPass
      idp={idp}
      onBack={handleBack}
    />
  );
};

export default SignIn;
