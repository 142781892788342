import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@bb-ui/react-library';
import { objectToQueryString } from '@bb-ui/auth/dist/utils';
import { useCognitoAuthContext } from 'contexts/CognitoAuthContext';
import { BrokerData, IdentityProvider } from 'hooks/useTenantIdps';
import { ErrorMessage } from 'components/ErrorMessage';
import { useSignInMethodContext } from 'contexts/SignInMethodContext/SignInMethodContext';
import { useIdpDetail } from 'hooks/useIdpDetail';

function verifyUrl(urlString: string, idp: IdentityProvider) {
  try {
    const url = new URL(urlString);
    const { brokerData, type } = idp;

    if (type === 'LearnConnector') {
      return url && brokerData?.allowedHostnames?.includes(url.hostname) ? urlString : null;
    }

    return url;
  } catch (error) {
    // Not even a valid URL.

    return false;
  }
}

export const Redirect: React.FC = () => {
  const { appState, idpId, returnUrl, setIdpId, setReturnUrl, setTenantId, tenantId } =
    useSignInMethodContext();
  const { idp, loading: idpLoading } = useIdpDetail(tenantId, idpId);
  const cognito = useCognitoAuthContext();
  const { t } = useTranslation();

  if (!idpId || !returnUrl || !tenantId) {
    return (
      <ErrorMessage
        title={t('global.error.generic')}
        message={t('global.error.missingRequiredConfig')}
      />
    );
  }

  if (cognito.status === 'loading' || idpLoading) {
    return <CircularProgress ariaLabel={t('global.loadingAriaLabel')!} />;
  }

  if (!returnUrl || !idp || !verifyUrl(returnUrl, idp)) {
    return (
      <ErrorMessage
        title={t('global.error.generic')}
        message={t('global.error.invalidReturnUrl')}
      />
    );
  }

  // On user sign-in.

  if (cognito.status === 'authenticated') {
    const { id, access, refresh } = cognito.tokens;
    const redirectUrl = `${returnUrl}#${objectToQueryString({
      refresh_token: refresh,
      id_token: id,
      access_token: access,
      expires_in: cognito.expiresIn.toString(),
      brokerType: idp.brokerType,
      app_state: appState,
    })}`;

    // We retain the IDP ID the user just chose so that we can sign them out
    // later with a consumer only providing tenant ID and return URL.

    setReturnUrl(undefined);
    setTenantId(undefined);
    window.location.assign(redirectUrl);
  }

  // On user sign-out.

  if (cognito.status === 'unauthenticated') {
    // We clear all retained state so that next time they sign in here, they
    // start from scratch.

    if (setIdpId) {
      setIdpId(undefined);
    }

    setReturnUrl(undefined);
    setTenantId(undefined);
    window.location.assign(returnUrl);
  }

  return <CircularProgress ariaLabel={t('global.loadingAriaLabel')!} />;
};

export default Redirect;
