import * as React from 'react';
import { useTenantIdps } from '../useTenantIdps';

/**
 * A convenience hook to retrieve detailed information about an IDP.
 */
export function useIdpDetail(tenantId?: string, idpId?: string) {
  const { error, loading, idps } = useTenantIdps(tenantId);
  const idp = React.useMemo(() => idps?.find(idp => idp.id === idpId), [idpId, idps]);

  if (!tenantId || !idpId) {
    return { loading: true };
  }

  return { error, loading, idp };
}
