import {
  CircularProgress,
  Link,
  Theme,
  Typography,
} from '@bb-ui/react-library';
import { createStyles, makeStyles } from '@material-ui/core';
import { useCognitoAuthContext } from 'contexts/CognitoAuthContext';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { ErrorMessage } from 'components/ErrorMessage';
import { Navigate } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => createStyles({
  loadingMessage: {
    marginBottom: theme.spacing(4),
    marginTop: '16px',
  },
  manualLink: {
    font: 'inherit',
  },
  manualMessage: {
    display: 'inline-flex',
    fontSize: '14px',
    marginTop: '40px',
  },
  manualPrompt: {
    '&::after': {
      content: '"\\00a0"', // &nbsp;
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
}));

export const SignInFederated: React.FC = (props) => {
  const styles = useStyles(props);
  const [signingIn, setSigningIn] = React.useState(false);
  const cognito = useCognitoAuthContext();
  const { t } = useTranslation();

  React.useEffect(() => {
    if (cognito.status === 'unauthenticated' && !signingIn) {
      setSigningIn(true);
      cognito.federatedSignIn();
    }
  }, [cognito, signingIn]);

  // Check specifically for an error message--sometimes `authError` is just "The
  // user is not authenticated" which isn't a true error condition.

  if (cognito.status === 'unauthenticated' && cognito.authError?.message) {
    return <ErrorMessage title={t('global.error.generic')} message={cognito.authError.message} />;
  }

  // If the user is authenticated already, send them directly to the consumer
  // redirect.

  if (cognito.status === 'authenticated') {
    return <Navigate to="/redirect" />;
  }

  return (
    <div data-testid="federated-sign-in" className={styles.root}>
      <CircularProgress
        data-testid="signing-in-federated"
        id="signing-in-federated"
        label={t('federatedSignIn.loading')}
      />
      {cognito.status === 'unauthenticated' && (
        <div className={styles.manualMessage}>
          <Typography className={styles.manualPrompt}>
            {t('federatedSignIn.manualPrompt')}
          </Typography>
          <Link
            className={styles.manualLink}
            component="button"
            onClick={() => cognito.federatedSignIn()}
          >
            {t('federatedSignIn.manualAction')}
          </Link>
        </div>
      )}
    </div>
  );
};

export default SignInFederated;
