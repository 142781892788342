import * as React from 'react';
import classnames from 'classnames';
import { ErrorBoundary } from 'react-error-boundary';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Typography } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { Helmet } from 'react-helmet';
import { ErrorMessage } from 'components/ErrorMessage';
import { Logo } from 'components/Logo';
import { Footer } from 'components/Footer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    '@global': {
      body: {
        margin: 0,
        padding: 0,
      },
    },
    root: {
      display: 'grid',
      gridTemplateRows: '1fr min-content',
      minHeight: '100vh',

      [theme.breakpoints.up('sm')]: {
        gridTemplateRows: '1fr 100px',
      },
    },
    main: {
      alignSelf: 'center',
      justifySelf: 'center',
      padding: theme.spacing(4),
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    placeholderLogo: {
      height: '30px',
      width: 'auto',
    },
    tenantLogo: {
      display: 'block',
      marginLeft: 'auto',
      marginBottom: '24px',
      marginRight: 'auto',
      maxHeight: '60px',
      maxWidth: '336px',

      [theme.breakpoints.up('sm')]: {
        marginBottom: '40px',
        maxHeight: '90px',
        maxWidth: '500px',
      },
    },
    tenantName: {
      marginBottom: '24px',
      textAlign: 'center',

      [theme.breakpoints.up('sm')]: {
        marginBottom: '40px',
      },
    },
  }),
);

export interface PageTemplateProps {
  tenant?: {
    logoDisplayName?: string;
    logoLink?: string;
    name?: string;
  };
}

export const PageTemplate: React.FC<PageTemplateProps> = (props) => {
  const { tenant } = props;
  const styles = useStyles(props);
  const { t } = useTranslation();
  // eslint-disable-next-line react/no-unstable-nested-components
  const ErrorFallback = ({ error }: { error?: Error }) => (
    <ErrorMessage
      title={t('global.error.generic')}
      message={error!.message}
      variant="fill-window"
    />
  );

  let tenantHeader = (
    <Logo
      className={classnames(styles.placeholderLogo, styles.tenantLogo)}
      data-testid="fallback-logo"
    />
  );

  if (tenant?.logoLink) {
    tenantHeader = (
      <img
        src={tenant.logoLink}
        alt={tenant.logoDisplayName}
        className={styles.tenantLogo}
        data-testid="tenant-logo"
      />
    );
  } else if (tenant?.name) {
    tenantHeader = (
      <Typography variant="h1" className={styles.tenantName}>
        {tenant.name}
      </Typography>
    );
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,400;0,700;1,400&amp;family=Open+Sans:ital,wght@0,400;0,600;0,700;1,400&amp;display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <div className={styles.root}>
        <main className={styles.main}>
          {tenantHeader}
          <div className={styles.content}>{props.children}</div>
        </main>
        <Footer />
      </div>
    </ErrorBoundary>
  );
};

export default PageTemplate;
