import { i18n, I18nThemeProvider } from '@bb-ui/i18n/dist';
import { PageTemplate } from 'components/PageTemplate';
import { AuthProvider } from 'contexts/AuthProvider';
import { SignInMethodProvider } from 'contexts/SignInMethodContext/SignInMethodContext';
import { IdpPicker } from 'pages/IdpPicker';
import { Redirect } from 'pages/Redirect';
import { SignIn } from 'pages/SignIn/SignIn';
import { SignOut } from 'pages/SignOut';
import * as React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

i18n.init({
  whitelist: ['ar', 'en'],
  ns: ['translation', 'links'],
});

// Use chris@bb.com / any password to sign in.
// http://localhost:3000/?tenantId=4ce56320-584c-4d40-b5cf-4b26c7b46cd1&returnUrl=http://localhost:3001/callback
//
// For SAML: chris.klimas@blackboard.com / test
// http://localhost:3000/?tenantId=f3ee537a-8b75-473f-965b-34b15204812e&returnUrl=http://localhost:3001/callback

export const App = () => (
  <React.Suspense fallback="">
    <I18nThemeProvider>
      <SignInMethodProvider>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <PageTemplate>
            <Routes>
              <Route path="/" element={<IdpPicker />} />
              <Route path="/*" element={<AuthProvider />}>
                <Route path="sign-in/:idpId" element={<SignIn />} />
                <Route path="redirect" element={<Redirect />} />
                <Route path="sign-out" element={<SignOut />} />
              </Route>
            </Routes>
          </PageTemplate>
        </BrowserRouter>
      </SignInMethodProvider>
    </I18nThemeProvider>
  </React.Suspense>
);
