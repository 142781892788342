import useAxios from 'axios-hooks';
import { apiUrl } from 'utils/apiUrl';

export interface BrokerData {
  clientId: string;
  userPoolId: string;
  defaultHostname?: string;
  // This is only available on LearnConnector IDPs.
  allowedHostnames?: string[];
  // These are only available on SAML IDPs.
  domain?: string;
  identityProviderName?: string;
}

export interface IdentityProvider {
  brokerData: BrokerData;
  brokerType: 'Cognito' | 'Auth0';
  clientId?: string;
  externalId?: string;
  description?: string;
  displayName: string;
  id: string;
  name: string;
  personAttribute: string;
  pool?: string;
  type: 'SAML' | 'LearnConnector' | 'AzureAD' | 'Testing';
}

interface IdentityProvidersResponse {
  results: IdentityProvider[];
}

/**
 * Returns identity providers associated with a tenant.
 */
export function useTenantIdps(tenantId?: string | null) {
  const [{ data, error, loading }] = useAxios<IdentityProvidersResponse>(
    apiUrl('sso', `tenants/${tenantId}/identityProviders`)
  );

  if (!tenantId) {
    return { loading: true };
  }

  let idps: IdentityProvider[] | undefined;

  if (data) {
    idps = data.results.filter((idp) => idp.brokerType === 'Cognito') ?? [];
  }

  return { error, idps, loading };
}

export default useTenantIdps;
