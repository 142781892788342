import {
  CircularProgress,
  createStyles,
  FormControlLabel,
  makeStyles,
  PrimaryButton,
  Radio,
  RadioGroup,
  Theme,
  Typography,
} from '@bb-ui/react-library';
import classnames from 'classnames';
import { ErrorMessage } from 'components/ErrorMessage';
import { useSignInMethodContext } from 'contexts/SignInMethodContext/SignInMethodContext';
import { useTenantIdps } from 'hooks/useTenantIdps';
import { orderBy } from 'lodash';
import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { CustomRadioSelectedIcon } from './CustomRadioSelectedIcon';

const radioHeight = 80;
const radioGap = 12;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footerLinks: {
      borderTop: `1px solid ${theme.palette.secondary.main}`,
      fontSize: '12px',
      paddingTop: theme.spacing(4),
    },
    loading: {
      textAlign: 'center',
    },
    prompt: {
      color: theme.palette.text.secondary,
      paddingBottom: theme.spacing(1),
      textAlign: 'center',
    },
    radio: {
      backgroundColor: theme.palette.background.paper,
      border: `2px solid ${theme.palette.background.b4}`,
      borderRadius: '4px',
      margin: 0,
      minHeight: radioHeight,
      paddingLeft: '25px',
      paddingRight: '25px',
    },
    radioChecked: {
      backgroundColor: '#f2f8fc',
      border: `2px solid ${theme.palette.focus.main}`,
    },
    radioGroup: {
      display: 'grid',
      gridGap: radioGap,
      maxHeight: `${3 * radioHeight + 2 * radioGap}px`,
      overflowY: 'auto',
    },
    root: {
      display: 'grid',
      gridGap: theme.spacing(3),
      [theme.breakpoints.up('sm')]: {
        width: '500px',
      },
    },
    tenantNameText: {
      paddingBottom: '30px',
      textAlign: 'center',
    },
  }),
);

/**
 * Allows the user to choose an IDP for a tenant to use.
 */
export const IdpPicker: React.FC = () => {
  const { tenantId, idpId, setIdpId } = useSignInMethodContext();
  const { idps } = useTenantIdps(tenantId);
  const [selectedIdpId, setSelectedIdpId] = React.useState<string>();
  const classes = useStyles();
  const { t } = useTranslation();

  if (!tenantId) {
    // This should have been set by useSignInMethodContext using the querystring
    // at minimum.

    return (
      <ErrorMessage
        data-testid="error-missing-tenant-id"
        title={t('global.error.missingTenantId')}
      />
    );
  }

  // Handle special case on first render where we have only one IDP and setIdpId has not been called yet.
  if (idps?.length === 1 && !idpId && setIdpId) {
    setIdpId(idps[0].id);
    return <Navigate to={`/sign-in/${idps[0].id}`} />;
  }

  // If an IDP ID has been set, either by the user choosing one here or us
  // choosing one for the user, redirect to the IDP sign in page.
  // Since idpId can be pulled from localStorage, we need to validate that
  // the IDP exists within the selected tenant.

  if (idpId && idps?.find((idp) => idp.id === idpId)) {
    return <Navigate to={`/sign-in/${idpId}`} />;
  }

  // If the tenant has no IDPs, we're stuck.

  if (tenantId && idps?.length === 0) {
    return <ErrorMessage data-testid="error-loading-idp-data" title={t('idpPicker.noIdpData')} />;
  }

  // If there's only one IDP, choose it for the user.

  if (tenantId && idps?.length === 1) {
    if (!setIdpId) {
      return (
        <ErrorMessage data-testid="error-no-tenant-id-set" title={t('idpPicker.noTenantSet')} />
      );
    }

    setIdpId(idps[0].id);
    return <CircularProgress ariaLabel={t('global.loadingAriaLabel')!} />;
  }

  // We have IDPs for the user to choose from.

  const handleSubmit: React.FormEventHandler = (e) => {
    // These conditions should never happen so long as the sign in method
    // context is correct.

    if (!selectedIdpId) {
      throw new Error(t('idpPicker.noIdpSet'));
    }

    if (!setIdpId) {
      throw new Error(t('idpPicker.noTenantSet'));
    }

    setIdpId(selectedIdpId);
  };

  return (
    <form data-testid="pool-picker" onSubmit={handleSubmit} className={classes.root}>
      <Typography variant="h2" className={classes.prompt}>
        {t('idpPicker.prompt')}
      </Typography>
      <RadioGroup
        aria-label={t('idpPicker.prompt')}
        name="login-connection"
        onChange={(e) => setSelectedIdpId(e.target.value)}
        className={classes.radioGroup}
        hasCustomLegend
      >
        {orderBy(idps, 'displayName').map((idp, index) => (
          <FormControlLabel
            value={idp.id}
            id={`idp-option-${index}`}
            data-testid="idp-option"
            checked={selectedIdpId === idp.id}
            control={<Radio checkedIcon={<CustomRadioSelectedIcon />} />}
            key={idp.id}
            label={
              <Trans
                i18nKey="idpPicker.optionLabel"
                components={[<span data-testid="idp-name" />]}
                values={{ connectionName: idp.displayName }}
              />
            }
            className={classnames(classes.radio, {
              [classes.radioChecked]: selectedIdpId === idp.id,
            })}
          />
        ))}
      </RadioGroup>
      <PrimaryButton
        disabled={selectedIdpId === undefined}
        type="submit"
        data-testid="idp-picker-submit"
      >
        {t('idpPicker.continue')}
      </PrimaryButton>
    </form>
  );
};

export default IdpPicker;
