// Holds state for the tenant and IDP the user has chosen to sign in with and
// the URL they will return to once done. This is also persisted to local
// storage so that after authenticating and the user is returned to the
// /federated-sign-in-callback route, we can initialize Cognito and return the
// user to where they should go.

import * as React from 'react';

export interface SignInMethodProps {
  appState?: string;
  idpId?: string;
  returnUrl?: string;
  setIdpId?: (value: string | undefined) => void;
  setReturnUrl: (value: string | undefined) => void;
  setTenantId: (value: string | undefined) => void;
  tenantId?: string;
}

export const SignInMethodContext = React.createContext<SignInMethodProps>({
  returnUrl: '',
  setReturnUrl: () => {},
  setTenantId: () => {},
});

export function useSignInMethodContext() {
  const signInMethod = React.useContext(SignInMethodContext);

  if (!signInMethod) {
    throw new Error('useSignInMethodContext must be used within a SignInMethod.Provider');
  }

  return signInMethod;
}

const storageKeys = {
  idpId: 'auth-idp-id',
  returnUrl: 'auth-return-url',
  tenantId: 'auth-tenant-id',
  appState: 'auth-app-state',
};

export const SignInMethodProvider: React.FC = ({ children }) => {
  const query = new URLSearchParams(window.location.search);
  const [idpId, setIdpId] = React.useState<string | undefined>(
    query.get('idpId') ?? window.localStorage.getItem(storageKeys.idpId) ?? undefined,
  );
  const [returnUrl, setReturnUrl] = React.useState<string | undefined>(
    query.get('returnUrl') ?? window.localStorage.getItem(storageKeys.returnUrl) ?? undefined,
  );
  const [tenantId, setTenantId] = React.useState<string | undefined>(
    query.get('tenantId') ?? window.localStorage.getItem(storageKeys.tenantId) ?? undefined,
  );
  const [appState] = React.useState<string | undefined>(
    query.get('appState') ?? window.localStorage.getItem(storageKeys.appState) ?? undefined,
  );

  // Whenever these values change, persist them to local storage.

  React.useEffect(() => {
    if (idpId) {
      window.localStorage.setItem(storageKeys.idpId, idpId);
    } else {
      window.localStorage.removeItem(storageKeys.idpId);
    }
  }, [idpId]);

  React.useEffect(() => {
    if (returnUrl) {
      window.localStorage.setItem(storageKeys.returnUrl, returnUrl);
    } else {
      window.localStorage.removeItem(storageKeys.returnUrl);
    }
  }, [returnUrl]);

  React.useEffect(() => {
    if (tenantId) {
      window.localStorage.setItem(storageKeys.tenantId, tenantId);
    } else {
      window.localStorage.removeItem(storageKeys.tenantId);
    }
  }, [tenantId]);

  React.useEffect(() => {
    if (appState) {
      window.localStorage.setItem(storageKeys.appState, appState);
    } else {
      window.localStorage.removeItem(storageKeys.appState);
    }
  }, [appState]);

  const context: SignInMethodProps = React.useMemo(
    () => ({
      appState,
      returnUrl,
      setReturnUrl,
      tenantId,
      setTenantId,
    }),
    [appState, returnUrl, tenantId],
  );

  // Only when a tenantId is set can IDPs be interacted with.

  if (tenantId) {
    context.idpId = idpId;
    context.setIdpId = setIdpId;
  }

  return <SignInMethodContext.Provider value={context}>{children}</SignInMethodContext.Provider>;
};
